import { Browser } from "@capacitor/browser";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Skeleton } from "@mui/material";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { SupportArticleLinks } from "@src/appV2/support/constants";
import { differenceInDays, format, parseISO } from "date-fns";
import pluralize from "pluralize";
import { type ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getCheckrRequirement, getIsExpiringSoon } from "../../utils";
import { useGetCheckrApplicationStatus } from "../api/useGetCheckrApplicationStatus";
import { useResendCheckrInvitation } from "../api/useSendCheckrInvitation";
import { AccountsRequirementCard } from "../Components";
import { CHECKR_REQUIREMENT_ID } from "../constants";
import { useGetDocumentDetailsRoute } from "../hooks/useGetDocumentDetailsRoute";
import { DocumentRequirementType, type HcpRequirement } from "../types";

interface CheckrAccountsRequirementProps {
  requirement: HcpRequirement;
  requirementType: DocumentRequirementType;
}

export function CheckrAccountsRequirement(props: CheckrAccountsRequirementProps): ReactElement {
  const { requirement, requirementType } = props;
  const { data: apiCheckrApplicationStatus, isLoading: isLoadingCheckrApplicationStatus } =
    useGetCheckrApplicationStatus();
  const { name, expiry: expirationDateOfRequirement } = requirement;
  const { [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks } = useCbhFlags();

  const history = useHistory();

  const location = useLocation();

  const { showErrorToast, showSuccessToast } = useToast();

  const {
    mutate: resendCheckrInvitation,
    data: updatedApiCheckrApplicationStatus,
    isLoading: isLoadingResendInvitation,
  } = useResendCheckrInvitation({
    onSuccess: () => {
      showSuccessToast("Invitation sent successfully");
    },
    onError: () => {
      showErrorToast("Something went wrong while sending invitation");
    },
  });

  const checkrApplicationStatus = updatedApiCheckrApplicationStatus ?? apiCheckrApplicationStatus;

  const isExpiringSoon = getIsExpiringSoon(requirement, requirementType);

  const {
    statusIcon,
    checkrRequirementStatus,
    checkrRequirementIsDisabled,
    checkrRequirementIsCompleted,
  } = getCheckrRequirement({
    documentRequirementType: requirementType,
    isExpiringSoon,
    checkrApplicationStatusResponse: checkrApplicationStatus,
  });

  const link = useGetDocumentDetailsRoute({
    shouldRouteToProfessionalReference: false,
    requirementId: CHECKR_REQUIREMENT_ID,
    documentRequirementType: requirementType,
  });
  return (
    <AccountsRequirementCard
      title={name}
      actionIsRequired={checkrRequirementIsCompleted || !checkrRequirementIsDisabled}
      statusIcon={statusIcon}
      disabled={isLoadingResendInvitation || checkrRequirementIsDisabled}
      onClick={async () => {
        if (checkrRequirementIsCompleted) {
          history.push(link, { returnLocation: location });
          return;
        }

        if (requirementType === DocumentRequirementType.REJECTED) {
          await Browser.open({
            url: supportLinks?.BACKGROUND_CHECKS ?? SupportArticleLinks.BACKGROUND_CHECKS,
          });
          return;
        }

        resendCheckrInvitation();
      }}
    >
      <>
        {isExpiringSoon && isDefined(expirationDateOfRequirement) && (
          <Text variant="body2">
            {`Expires in ${pluralize(
              "day",
              differenceInDays(parseISO(expirationDateOfRequirement), new Date()),
              true
            )} (${format(parseISO(expirationDateOfRequirement), "PP")})`}
          </Text>
        )}
        {isLoadingCheckrApplicationStatus ? (
          <Skeleton />
        ) : (
          isDefined(checkrRequirementStatus) && (
            <>
              {isDefined(checkrRequirementStatus?.description) && (
                <Text variant="body2">{checkrRequirementStatus?.description}</Text>
              )}
              {isDefined(checkrRequirementStatus?.linkText) && (
                <Text sx={{ color: (theme) => theme.palette.action.active }}>
                  <b>{checkrRequirementStatus?.linkText}</b>
                </Text>
              )}
            </>
          )
        )}
      </>
    </AccountsRequirementCard>
  );
}
