// eslint-disable-next-line no-restricted-imports
import { gql } from "@apollo/client";
import { isDefined } from "@clipboard-health/util-ts";
import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
// eslint-disable-next-line no-restricted-imports
import { print } from "graphql";

import { type HcpRequirementStatusApiResponse, hcpRequirementStatusResponse } from "../types";

export const defaultHcpRequirementStatusApiResponse: HcpRequirementStatusApiResponse = {
  requirements: [],
  completed: [],
  pending: [],
  expired: [],
  missing: [],
  optionalRequirements: [],
  validOptionalRequirements: [],
  expiredOptionalRequirements: [],
  missingOptionalRequirements: [],
  rejected: [],
  optional: [],
  miscellaneous: {
    _id: "",
    name: "",
    description: "",
    instructions: "",
    visibleToHCP: false,
  },
};

/**
 * @deprecated
 * Graph QL is is not supported in App v2 and this will be taken out after the Document UI revamp is done.
 * Please do not use this query.
 * https://clipboardhealth.atlassian.net/browse/CH-22376
 */
export const GET_REQUIREMENTS_STATUS = gql`
  query GET_REQUIREMENTS_STATUS($hcpId: ObjectId!, $hcfIds: [ObjectId!]) {
    hcpRequirementStatus(hcpId: $hcpId, hcfIds: $hcfIds) {
      miscellaneous {
        _id
        name
        instructions
        description
      }
      requirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        disableWorkerUpload
        checklist {
          enabled
          description
          items
        }
        visibleToHCP
        expiry
      }
      completed
      pending
      expired
      missing
      optionalRequirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        visibleToHCP
        expiry
        disableWorkerUpload
        checklist {
          enabled
          description
          items
        }
      }
      validOptionalRequirements
      expiredOptionalRequirements
      missingOptionalRequirements
      rejected
      mostRecentCovidDoc {
        covidTest
      }
    }
  }
`;

/**
 * @deprecated
 * Graph QL is is not supported in App v2 and this will be taken out after the Document UI revamp is done.
 * Please do not use this query.
 * https://clipboardhealth.atlassian.net/browse/CH-22376
 */
export function deprecatedGetRequirementStatusDoNotUseGraphQlCH23034(
  workerId: string,
  hcfIds?: string[]
) {
  return async function () {
    const response = await post({
      url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
      data: JSON.stringify({
        operationName: "GET_REQUIREMENTS_STATUS",
        query: print(GET_REQUIREMENTS_STATUS),
        variables: {
          hcpId: workerId,
          ...(isDefined(hcfIds) && hcfIds.length > 0 ? { hcfIds } : {}),
        },
      }),
      responseSchema: hcpRequirementStatusResponse,
    });
    return response.data;
  };
}

/**
 * @deprecated
 * Graph QL is is not supported in App v2 and this will be taken out after the Document UI revamp is done.
 * Please do not use this query.
 * https://clipboardhealth.atlassian.net/browse/CH-22376
 */
export const GET_REQUIREMENTS_STATUS_PATH = "/graphql/getRequirementStatus";
