// eslint-disable-next-line no-restricted-imports
import { gql } from "@apollo/client";
import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
// eslint-disable-next-line no-restricted-imports
import { print } from "graphql";

import {
  type GetFilteredDocumentQueryParams,
  getFilteredDocumentsApiResponseSchema,
} from "./types";

const GET_FILTERED_DOCUMENTS_URL = gql`
  query GET_FILTERED_DOCUMENTS(
    $hcpId: ObjectId
    $limit: Int
    $sort: Sort
    $filter: DocumentFilter
  ) {
    documentList(hcpId: $hcpId, limit: $limit, sort: $sort, filter: $filter) {
      _id
      createdAt
    }
  }
`;

/**
 * @deprecated GraphQL is deprecated in V2, and this will be replaced with a REST API.
 * This is a temporary solution to get the app working. Do not use this for new code. Use the REST API instead.
 * We will remove this once the REST API is ready. Jira Ticket to introduce Rest API: CH-23232
 */
export function deprecatedGetFilteredDocumentsUrlDoNotUseGraphQlCH23232(
  params: GetFilteredDocumentQueryParams
) {
  const { hcpId, limit = 1, sort, filter } = params;
  return async function () {
    const response = await post({
      url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
      data: JSON.stringify({
        query: print(GET_FILTERED_DOCUMENTS_URL),
        variables: {
          hcpId,
          limit,
          sort,
          filter,
        },
      }),
      responseSchema: getFilteredDocumentsApiResponseSchema,
    });
    return response.data;
  };
}

/**
 * @deprecated GraphQL is deprecated in V2, and this will be replaced with a REST API.
 * This is a temporary solution to get the app working. Do not use this for new code. Use the REST API instead.
 * We will remove this once the REST API is ready. Jira Ticket to introduce Rest API: CH-23232
 */
export const DEPRECATED_DO_NOT_USE_GRAPHQL_GET_FILTERED_DOCUMENTS_QUERY_KEY =
  "/graphql/GET_FILTERED_DOCUMENTS";
