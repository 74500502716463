// eslint-disable-next-line no-restricted-imports
import { gql } from "@apollo/client";
import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
// eslint-disable-next-line no-restricted-imports
import { print } from "graphql";

import {
  type GetHcpMissingRequirementsForDateQueryParams,
  getHcpMissingRequirementsForDateResponseSchema,
} from "./types";

const GET_MISSING_REQUIREMENTS_FOR_DATE_QUERY = gql`
  query GET_MISSING_REQUIREMENTS_FOR_DATE(
    $hcfId: ObjectId!
    $date: DateTime!
    $qualification: String!
    $notifySupport: Boolean
  ) {
    hcpMissingRequirementsForDate(
      hcfId: $hcfId
      date: $date
      qualification: $qualification
      notifySupport: $notifySupport
    ) {
      _id
      reqId
      name
      level
      hcfType
      requiredBy
      instructions
      description
      visibleToHCP
      expiry
    }
  }
`;

/**
 * @deprecated GraphQL is deprecated in V2, and this will be replaced with a REST API.
 * This is a temporary solution to get the app working. Do not use this for new code. Use the REST API instead.
 * We will remove this once the REST API is ready. Jira Ticket to introduce Rest API: CH-25924
 */
export async function deprecatedDoNotUseGraphQlCH25924(
  params: GetHcpMissingRequirementsForDateQueryParams
) {
  const { hcfId, date, qualification, notifySupport } = params;
  const response = await post({
    url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
    data: JSON.stringify({
      operationName: "GET_MISSING_REQUIREMENTS_FOR_DATE",
      query: print(GET_MISSING_REQUIREMENTS_FOR_DATE_QUERY),
      variables: {
        hcfId,
        date,
        qualification,
        notifySupport,
      },
    }),
    responseSchema: getHcpMissingRequirementsForDateResponseSchema,
  });

  return response.data;
}

/**
 * @deprecated GraphQL is deprecated in V2, and this will be replaced with a REST API.
 * This is a temporary solution to get the app working. Do not use this for new code. Use the REST API instead.
 * We will remove this once the REST API is ready. Jira Ticket to introduce Rest API: CH-25924
 */
export const DEPRECATED_DO_NOT_USE_GRAPHQL_GET_MISSING_REQUIREMENTS_FOR_DATE_QUERY_KEY =
  "/graphql/GET_MISSING_REQUIREMENTS_FOR_DATE";
